import React from "react";
import "./Checkbox.css";

type CheckboxProps = {
  label?: string;
  checked?: boolean;
  setChecked: (val: boolean) => void;
};

export const Checkbox = ({ label, checked, setChecked }: CheckboxProps) => {
  return (
    <div className="checkbox-container">
      <input
        type="checkbox"
        onChange={(e) => setChecked(e.target.checked)}
        checked={checked}
      />
      {label && <span>{label}</span>}
    </div>
  );
};
