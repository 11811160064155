import { CoverEnum, DataReported } from "./../types/ApiTypes";

export const SHADOW_FILTERS_LABELS = {
  [CoverEnum.LOCATIONS]: ["Loc Number", "Location Name"],
  [CoverEnum.APPT_TYPE]: ["Appointment Type"],
  [CoverEnum.PROVIDERS]: ["Prov Number", "Provider Name"],
};
export const SHADOW_FILTERS_TITLE = {
  [CoverEnum.LOCATIONS]: "Select Location (s)",
  [CoverEnum.APPT_TYPE]: "Select Appointment Type (s)",
  [CoverEnum.PROVIDERS]: "Select Provider (s)",
};
export const SHADOW_FILTERS_SELECTED = {
  [CoverEnum.LOCATIONS]: "Location (s)",
  [CoverEnum.APPT_TYPE]: "Appointment Type (s)",
  [CoverEnum.PROVIDERS]: "Provider (s)",
};
export const SHADOW_REPORTS_LABELS: { [key in DataReported]: string } = {
  [DataReported.APPOINTMENT]: "VW Appointment",
  [DataReported.PATIENT]: "VW Patient",
  [DataReported.VISIT]: "VW Visit",
  [DataReported.PRODUCT_PICKUP]: "VW Product Pick-Up",
  [DataReported.RECALL]: "VW Recall",
  [DataReported.SLOT]: "AV Slot",
  [DataReported.APPOINTMENT_BLOCK]: "Appointment Block",
};
