import React, { useEffect, useState } from "react";
import { Button } from "../../../../shared/components/Atoms/Button/Button";
import {
  SHADOW_FILTERS_LABELS,
  SHADOW_FILTERS_TITLE,
} from "../../../constants/Dictionary";
import { CoverEnum } from "../../../types/ApiTypes";
import { FilterLabel } from "../../../types/AppTypes";
import { Checkbox } from "../../Atoms/Checkbox/Checkbox";
import { Table } from "../../Molecules/Table/Table";

interface FilterTableProps {
  type: CoverEnum.APPT_TYPE | CoverEnum.LOCATIONS | CoverEnum.PROVIDERS;
  onSelect: (val: string[]) => void;
  selected?: string[];
  data?: FilterLabel[];
  loading?: boolean;
  goBack: () => void;
  showBack?: boolean;
}
export const FilterTable = ({
  type,
  onSelect,
  selected,
  data,
  loading,
  goBack,
  showBack,
}: FilterTableProps) => {
  const [filter, setFilter] = useState<string[]>(selected || []);

  const handleSelect = () => {
    onSelect(filter);
  };

  useEffect(() => {
    selected && selected.length > 0 && setFilter(selected);
  }, [selected]);

  const COLUMS = [
    ...SHADOW_FILTERS_LABELS[type],
    <Checkbox
      key="table-header"
      label="All"
      checked={data && filter.length === data.length}
      setChecked={() =>
        data &&
        setFilter(
          filter.length !== data.length ? data.map((item) => item.id) : []
        )
      }
    />,
  ];

  const DATA =
    data &&
    data.map((item) => [
      ...(type === CoverEnum.APPT_TYPE ? [item.label] : [item.id, item.label]),
      <Checkbox
        key={`filter-row-${item.id}`}
        checked={filter.includes(item.id)}
        setChecked={() =>
          setFilter(
            filter.includes(item.id)
              ? filter.filter((i) => i !== item.id)
              : [...filter, item.id]
          )
        }
      />,
    ]);

  return (
    <div className="filter-table-container">
      <Table
        title={SHADOW_FILTERS_TITLE[type]}
        colums={COLUMS}
        datas={DATA || []}
        footer={
          <>
            {showBack && <Button label="Back" onClick={() => goBack()} back />}
            <Button
              label="Select"
              onClick={handleSelect}
              disabled={filter.length === 0}
            />
          </>
        }
        loading={loading}
      />
    </div>
  );
};
