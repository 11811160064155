import { CoverEnum } from "../types/ApiTypes";
import { ShadowFilters } from "../types/AppTypes";

export const DEFAULT_SHADOW_FILTERS = {
  [CoverEnum.COVER_NUMBER]: "",
  [CoverEnum.APPT_TYPE]: [],
  [CoverEnum.LOCATIONS]: [],
  [CoverEnum.PROVIDERS]: [],
};
export const SHADOW_FILTERS: ShadowFilters[] = [
  CoverEnum.LOCATIONS,
  CoverEnum.APPT_TYPE,
  CoverEnum.PROVIDERS,
];
