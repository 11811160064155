export const formatNumber = (number: number, digits = 1) => {
  if (number && !isNaN(number) && isFinite(number)) {
    let newNumber;
    Number.isInteger(number)
      ? (newNumber = number)
      : (newNumber = number.toFixed(digits));
    const addComma = newNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return addComma;
  } else {
    return 0;
  }
};
export const kFormatter = (num: number) => {
  return Math.abs(num) > 999
    ? `${Math.sign(num) * (Math.abs(num) / 1000)}k`
    : Math.sign(num) * Math.abs(num);
};
