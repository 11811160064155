import React from "react";
import { MdChevronLeft } from "react-icons/md";
import "./Button.css";

type ButtonProps = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  halfLeft?: boolean;
  back?: boolean;
  iconLeft?: JSX.Element;
};
export const Button = ({
  label,
  disabled,
  halfLeft,
  onClick,
  back,
  iconLeft,
}: ButtonProps) => {
  return (
    <button
      className={`button-wrapper ${disabled && "disabled"} ${
        halfLeft && "halfLeft"
      } ${(back || iconLeft) && "back"}`}
      onClick={onClick}
    >
      {(back || iconLeft) && (iconLeft || <MdChevronLeft />)}
      {label}
    </button>
  );
};
