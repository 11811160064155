import React from "react";
import "./Loading.css";
import { CgSpinner } from "react-icons/cg";

type LoadingProps = {
  full?: boolean;
  children?: JSX.Element;
};

export const Loading = ({ full, children }: LoadingProps) => {
  return (
    <div className={`loading-container ${full && "full"}`}>
      <CgSpinner />
      {children && children}
    </div>
  );
};
