import React from "react";

import { ComponentStory, ComponentMeta } from "@storybook/react";

import { Button as Component } from "../components/Atoms/Button/Button";
import "../index.css";

export default {
  title: "Components/Atoms",
  component: Component,
  args: {
    label: "Button",
  },
  argTypes: {},
} as ComponentMeta<typeof Component>;

export const Button: ComponentStory<typeof Component> = (args) => (
  <Component {...args} />
);
