export enum CoverEnum {
  COVER_NUMBER = "coverKey",
  LOCATIONS = "locations",
  APPT_TYPE = "apptTypes",
  PROVIDERS = "providers",
}
export interface CoverFiltersBody {
  [CoverEnum.COVER_NUMBER]: string | null;
  [CoverEnum.APPT_TYPE]: string[] | null;
  [CoverEnum.LOCATIONS]: string[] | null;
}
export interface CoverFilters {
  coverKeys: string[];
  [CoverEnum.APPT_TYPE]: string[];
  [CoverEnum.LOCATIONS]: { [key: string]: string };
  [CoverEnum.PROVIDERS]: { [key: string]: string };
}
export enum ShadowStatus {
  GREEN = "GREEN",
  RED = "RED",
  YELLOW = "YELLOW",
}
export type ShadowReport = {
  avgCount: number;
  shadowDataReported: DataReported;
  uploadStatus: ShadowStatus;
}[];
export enum DataReported {
  APPOINTMENT = "APPOINTMENT",
  PATIENT = "PATIENT",
  VISIT = "VISIT",
  PRODUCT_PICKUP = "PRODUCT_PICKUP",
  RECALL = "RECALL",
  SLOT = "SLOT",
  APPOINTMENT_BLOCK = "APPOINTMENT_BLOCK",
}
export type ShadowReportDetails = {
  avgPastValue: number;
  currentValue: number;
  dayOfWeekName: string;
  decrement: number;
  uploadDate: string;
  significantDecline: boolean;
};
export type ShadowReportChartDetails = {
  coverKey: string;
  locationName: string;
  providerName: string;
  uploadDate: string;
  nrOfUploads: string;
  pmsLocationID: string;
}[];
