import React from "react";
import "./LoadingError.css";

export const LoadingError = ({ noText }: { noText?: boolean }) => (
  <p className={`loading-error ${noText && "noText"}`}>
    {!noText && "An error occurred while loading data"}
  </p>
);

export const NoDataFound = () => (
  <p className="loading-error no-data">No data found with selected filters</p>
);

export const ErrorFound = ({ text }: { text: string }) => (
  <p className="loading-error no-data">{text}</p>
);
