import React, { useState } from "react";
import { FilterLabel } from "../../../types/AppTypes";
import { Chip } from "../../Atoms/Chip/Chip";
import "./ChipsList.css";

type ChipsListProps = {
  label: string;
  data: FilterLabel[];
  onRemove: (id: string) => void;
};

export const ChipsList = ({ label, data, onRemove }: ChipsListProps) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const MAX_TO_SHOW = 5;
  return (
    <div className="chips-list-container">
      <span className="chips-list-label">{label}</span>
      <div className="chips-container">
        {data &&
          data
            .slice(0, showAll ? data.length : MAX_TO_SHOW)
            .map((item, idx) => {
              return (
                <Chip
                  key={`${item}-${idx}`}
                  label={item.label}
                  onClick={() => onRemove(item.id)}
                />
              );
            })}
        {data.length > MAX_TO_SHOW && (
          <span
            className="show-all-button"
            onClick={() => setShowAll(!showAll)}
          >
            {!showAll ? "Show All" : "Hide All"}
          </span>
        )}
      </div>
    </div>
  );
};
