const ENDPOINT = process.env.REACT_APP_UI_LIBRARY_ENDPOINT || "";
const ENDPOINT_ESSILORDITAC =
  process.env.REACT_APP_ESSILORDITAC_API_ENDPOINT || "";
export const API_ENDPOINT = `${
  ENDPOINT.charAt(ENDPOINT.length - 1) === "/"
    ? ENDPOINT.slice(0, -1)
    : ENDPOINT
}/api/`;
export const API_ENDPOINT_ESSILORDITAC = `${
  ENDPOINT_ESSILORDITAC.charAt(ENDPOINT_ESSILORDITAC.length - 1) === "/"
    ? ENDPOINT_ESSILORDITAC.slice(0, -1)
    : ENDPOINT_ESSILORDITAC
}/`;
export const BASE_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};
export const TOKEN_HEADER_NAME = "X-Auth-Token";
export const ESSILORDITAC_TOKEN_HEADER_NAME = "Authorization";
export const ESSILORDITAC_COVERKEY_HEADER_NAME = "X-Cover-Key";
