import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button } from "../../../shared/components/Atoms/Button/Button";
import { CoverEnum, CoverFilters, DataReported } from "../../types/ApiTypes";
import {
  LabelledFilters,
  SelectedCoverFilters,
  ShadowFilters,
} from "../../types/AppTypes";
import {
  DateRange,
  DateRangeSelection,
} from "../../components/Atoms/DateRange/DateRange";
import "./CoverReports.css";
import { addDays } from "date-fns";
import { LoadingError } from "../../../shared/components/Atoms/LoadingError/LoadingError";
import { useApi } from "../../../shared/hooks/useApi";
import MainService from "../../services/MainService";
import {
  DEFAULT_SHADOW_FILTERS,
  SHADOW_FILTERS,
} from "../../constants/AppConstants";
import { ChartReport } from "../../components/Organisms/ChartReport/ChartReport";
import { ChipsSelected } from "../../components/Organisms/ChipSelected/ChipsSelected";
import { FilterTable } from "../../components/Organisms/FilterTable/FilterTable";
import { ShadowReportTable } from "../../components/Organisms/ShadowReportTable/ShadowReportTable";
import { getIds, getSelectedFilters } from "../../utils/CoverReportsUtils";
import { ChartDetails } from "../../components/Organisms/ChartDetails/ChartDetails";

export const COVER_REPORT_TABS = [
  {
    id: 0,
    label: "Shadow Upload Report",
  },
  {
    id: 1,
    label: "Shadow Detail Report",
  },
  {
    id: 2,
    label: "Shadow Double Booking Report",
  },
];

type UploadReportProps = {
  selected: SelectedCoverFilters;
  setSelected: (val: SelectedCoverFilters) => void;
  coverKey: string;
};

// eslint-disable-next-line react/display-name
export const UploadReport = forwardRef(
  ({ selected, setSelected, coverKey }: UploadReportProps, ref) => {
    const [page, setPage] = useState<number>(0);
    const [report, setReport] = useState<DataReported>();
    const [range, setRange] = useState<DateRangeSelection>({
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    });

    useEffect(() => {
      setSelected({ ...selected, [CoverEnum.COVER_NUMBER]: coverKey });
    }, [coverKey]);

    useImperativeHandle(ref, () => ({
      editSearch() {
        (selected[CoverEnum.COVER_NUMBER] as string) && nextPage(0);
      },
    }));

    const [res, error, loading] = useApi<CoverFilters>(
      MainService.getShadowFilters({
        [CoverEnum.COVER_NUMBER]: selected[CoverEnum.COVER_NUMBER] as string,
        [CoverEnum.APPT_TYPE]: selected[CoverEnum.APPT_TYPE] as string[],
        [CoverEnum.LOCATIONS]: selected[CoverEnum.LOCATIONS] as string[],
      }),
      [selected]
    );
    const [filters, setFilters] = useState<LabelledFilters>();

    useEffect(() => {
      if (res) {
        const filteredResponse = {
          ...res,
          apptTypes: res.apptTypes
            .filter((item) => item)
            .reduce((prev, curr) => (prev = { ...prev, [curr]: curr }), {}),
        } as CoverFilters;

        const newKeys = Object.keys(filteredResponse)
          .filter(
            (key) =>
              filteredResponse[key] &&
              Object.keys(filteredResponse[key]).length > 0
          )
          .filter((key) => SHADOW_FILTERS.includes(key as ShadowFilters));

        setFilters({
          ...filters,
          ...newKeys.reduce(
            (prev, acc) =>
              (prev = {
                ...prev,
                [acc]:
                  filteredResponse[acc] &&
                  Object.entries(filteredResponse[acc]).map(([key, value]) => ({
                    id: key,
                    label: value || key,
                  })),
              }),
            {} as LabelledFilters
          ),
        });
      }
    }, [res]);

    const nextPage = (pageNum?: number) => {
      pageNum || pageNum === 0 ? setPage(pageNum) : setPage(page + 1);
    };

    const goBack = () => {
      setPage(page - 1);
    };

    const handleSearchSubmit = (type: CoverEnum, val: string | string[]) => {
      setSelected({ ...selected, [type]: val });
      nextPage();
    };

    const resetSearch = () => {
      setSelected({
        ...DEFAULT_SHADOW_FILTERS,
        [CoverEnum.COVER_NUMBER]: coverKey,
      });
      nextPage(0);
    };

    const showReport = (rep: DataReported) => {
      setReport(rep);
      nextPage();
    };

    return (
      <>
        <ChipsSelected
          page={page}
          selected={selected}
          setSelected={setSelected}
          range={range}
          filters={getSelectedFilters(selected, filters)}
          noEdit={page > 4}
        />
        {error && page !== 0 && <LoadingError />}
        {!error && (
          <>
            {SHADOW_FILTERS.map((key, idx) => (
              <>
                {page === idx && (
                  <FilterTable
                    type={key}
                    onSelect={(val) => handleSearchSubmit(key, val)}
                    selected={
                      filters &&
                      (selected[key].length > 0
                        ? (selected[key] as string[])
                        : getIds(filters[key]))
                    }
                    data={filters && filters[key]}
                    loading={loading}
                    goBack={goBack}
                    showBack={page !== 0}
                  />
                )}
              </>
            ))}
            {page === 3 && (
              <div className="filter-table-container date-range">
                <p className="date-range-select-title">Select a Date Range</p>
                <div className="date-range-container">
                  <DateRange
                    range={range}
                    setRange={(item) => setRange(item as DateRangeSelection)}
                  />
                </div>
                <div className="date-range-container-footer">
                  <Button label="Back" onClick={() => goBack()} back />
                  <Button
                    label="Select"
                    onClick={() => nextPage()}
                    disabled={!range.endDate || !range.startDate}
                  />
                </div>
              </div>
            )}
            {page === 4 && (
              <ShadowReportTable
                selected={selected}
                range={range}
                showReport={showReport}
                goBack={goBack}
              />
            )}
            {page === 5 && report && filters && (
              <ChartReport
                selected={selected}
                range={range}
                report={report}
                nextPage={() => nextPage()}
                goBack={goBack}
              />
            )}
            {page === 6 && report && (
              <div className="filter-table-container overflow-chart-details">
                <ChartDetails
                  selected={selected}
                  range={range}
                  report={report}
                  goBack={goBack}
                />
              </div>
            )}
          </>
        )}
        {page >= 1 && page <= 3 && (
          <div className="skip-buttons-container">
            <Button label="Clear Search" onClick={resetSearch} />
            <Button label="Show Report" onClick={() => nextPage(4)} />
          </div>
        )}
      </>
    );
  }
);
