import React, { useEffect, useRef, useState } from "react";
import "./DateRange.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange as DateRangePicker } from "react-date-range";
import format from "date-fns/format";
import { FaRegCalendarAlt } from "react-icons/fa";
import { RangeKeyDict, Range } from "react-date-range";

type DateRangeProps = {
  range: Range;
  setRange: (item: Range) => void;
};

export type DateRangeSelection = {
  startDate: Date | undefined;
  endDate: Date | undefined;
  key: string;
};

export const DateRange = ({ range, setRange }: DateRangeProps) => {
  const [open, setOpen] = useState(false);
  const refOne = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  const hideOnEscape = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e: MouseEvent) => {
    if (refOne.current && !refOne.current.contains(e.target as Node)) {
      setOpen(false);
    }
  };

  return (
    <div className="date-range-container">
      <span className="chips-list-label">Date Range</span>
      <div className="date-labels-container">
        <div className="labels-container">
          <span>Start date</span>
          <span>End date</span>
        </div>
        <div className="calendar-container">
          <div className="calendarWrap">
            <input
              value={
                range.startDate &&
                range.endDate &&
                `${format(range.startDate, "MM/dd/yyyy")} to ${format(
                  range.endDate,
                  "MM/dd/yyyy"
                )}`
              }
              readOnly
              className="inputBox"
              onClick={() => setOpen((op) => !op)}
            />

            <div ref={refOne}>
              {open && (
                <DateRangePicker
                  onChange={(item: RangeKeyDict) => setRange(item.selection)}
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  ranges={[range]}
                  months={1}
                  direction="horizontal"
                  className="calendarElement"
                />
              )}
            </div>
          </div>
          <FaRegCalendarAlt />
        </div>
      </div>
    </div>
  );
};
