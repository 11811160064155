import { BarCustomLayerProps, ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import React from "react";
import { capitalize } from "../../../../shared/utils/stringUtils";
import { ShadowReportDetails } from "../../../types/ApiTypes";
import { ChartData } from "../../../types/AppTypes";
import { getCalculatedBars } from "../../../utils/ReportChartUtils";
import "./ReportChart.css";

const MARGIN = 5;
const BAR_WIDTH = 12;
const BAR_RADIUS = 5;

interface ReportChartProps {
  datas: ShadowReportDetails[];
}
export const ReportChart = ({ datas }: ReportChartProps) => {
  const DATA = datas
    .sort((a, b) => (moment(a.uploadDate) > moment(b.uploadDate) ? 1 : -1))
    .slice(1)
    .map((item) => ({
      day: item.dayOfWeekName,
      value: !item.significantDecline ? item.currentValue : item.avgPastValue,
      date: item.uploadDate,
    }));

  const maxValue = DATA.reduce(
    (acc, cur) => (acc > cur.value ? acc : cur.value),
    0
  );

  return (
    <div className="report-chart-container">
      <ResponsiveBar
        data={DATA}
        keys={["value"]}
        maxValue={maxValue + 40}
        isInteractive={false}
        indexBy="day"
        margin={{ top: 20, right: 0, bottom: 50, left: 70 }}
        padding={0}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        axisTop={null}
        axisRight={null}
        axisLeft={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 40,
          renderTick: (t) => (
            <text
              x={t.x}
              y={t.textY}
              fill="#202020"
              fontSize={12}
              fontWeight={600}
              dominantBaseline="middle"
              textAnchor="middle"
            >
              {`${capitalize(t.value.slice(0, 3).toLowerCase())} ${moment(
                DATA.find((item) => item.day === t.value)?.date
              ).format("DD")}`}
            </text>
          ),
        }}
        enableGridX={false}
        enableGridY={false}
        enableLabel={false}
        layers={["axes", (d) => Bars(d, datas), (d) => Lines(d, datas), Text]}
      />
    </div>
  );
};

type ChartProp = BarCustomLayerProps<ChartData>;

const Bars = (d: ChartProp, datas: ShadowReportDetails[]) => (
  <>
    {d.bars.map((b) => {
      const x1 = b.x + b.width / 2 - BAR_WIDTH / 2;
      const x2 = b.x + b.width / 2 + BAR_WIDTH / 2;
      const y1 = b.y;
      const y2 = b.y + b.height;
      const cp = BAR_RADIUS / 2.5;

      const { barData, alertOffset } = getCalculatedBars(b, datas);

      return (
        <>
          <defs>
            <linearGradient
              id="alert-gradient"
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <stop offset="0%" stopColor="#e52f7b" />
              <stop offset="100%" stopColor="#ea90b5" />
            </linearGradient>
          </defs>
          {barData && barData.significantDecline && (
            <path
              key={`bar-${b.index}-border`}
              d={`
              M${x1} ${y1 + BAR_RADIUS}
              C${x1} ${y1 - cp} ${x2} ${y1 - cp} ${x2} ${y1 + BAR_RADIUS}
              L${x2} ${y2 - BAR_RADIUS}
              C${x2} ${y2 + cp} ${x1} ${y2 + cp} ${x1} ${y2 - BAR_RADIUS}
              Z
            `}
              stroke="rgba(203, 198, 215, 0.25)"
              strokeWidth={10}
              fill="none"
            />
          )}
          {barData && (barData.currentValue || barData.significantDecline) && (
            <path
              key={`bar-${b.index}`}
              d={`
              M${x1} ${y1 + BAR_RADIUS}
              C${x1} ${y1 - cp} ${x2} ${y1 - cp} ${x2} ${y1 + BAR_RADIUS}
              L${x2} ${y2 - BAR_RADIUS}
              C${x2} ${y2 + cp} ${x1} ${y2 + cp} ${x1} ${y2 - BAR_RADIUS}
              Z
            `}
              stroke="none"
              fill="#a3a9d9"
            />
          )}
          {barData && barData.significantDecline && (
            <path
              key={`bar-${b.index}-alert`}
              d={`
              M${x1} ${y1 + BAR_RADIUS + alertOffset}
              C${x1} ${y1 - cp + alertOffset} ${x2} ${
                y1 - cp + alertOffset
              } ${x2} ${y1 + BAR_RADIUS + alertOffset}
              L${x2} ${y2 - BAR_RADIUS}
              C${x2} ${y2 + cp} ${x1} ${y2 + cp} ${x1} ${y2 - BAR_RADIUS}
              Z
            `}
              stroke="none"
              fill="url('#alert-gradient')"
            />
          )}
        </>
      );
    })}
  </>
);

const Text = (d: ChartProp) => (
  <text
    x={-d.margin.left + MARGIN * 2}
    y={d.innerHeight + MARGIN}
    fill="#a39f9f"
    fontSize={10}
    fontWeight={600}
  >
    Days
  </text>
);

const Lines = (d: ChartProp, datas: ShadowReportDetails[]) => {
  const props = {
    stroke: "#e3e4e7",
    strokeWidth: 2,
    fill: "none",
  };
  return (
    <>
      <path
        d={`
        M${-d.margin.left + MARGIN} ${d.innerHeight + d.margin.bottom / 3} 
        H${d.innerWidth}
      `}
        {...props}
      />
      <path
        d={`
        M${-d.margin.left + MARGIN} ${-d.margin.top + MARGIN} 
        H${d.innerWidth}
      `}
        {...props}
      />
      <path
        d={`
        M${-d.margin.left + MARGIN * 3} ${d.height / 4}
        ${d.bars.map((b) => {
          const { barAlert, alertOffset } = getCalculatedBars(b, datas);
          return `L${b.x + b.width / 2} ${!barAlert ? b.y : b.y + alertOffset}`;
        })}
      `}
        {...props}
        stroke="#1676ff"
        strokeDasharray="6 3"
        strokeLinejoin="round"
      />
    </>
  );
};
