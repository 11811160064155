import React from "react";
import { IoIosClose } from "react-icons/io";

export type ChipsProps = {
  label: string;
  onClick: () => void;
};

export const Chip = ({ label, onClick }: ChipsProps) => {
  return (
    <div className="chip-wrapper" onClick={onClick}>
      <span>{label}</span>
      <div className="chip-cancel">
        <IoIosClose />
      </div>
    </div>
  );
};
