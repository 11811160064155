import moment from "moment";
import React from "react";
import { SHADOW_FILTERS } from "../../../constants/AppConstants";
import { SHADOW_FILTERS_SELECTED } from "../../../constants/Dictionary";
import { CoverEnum } from "../../../types/ApiTypes";
import {
  SelectedCoverFilters,
  LabelledFilters,
  FilterLabel,
} from "../../../types/AppTypes";
import { DateRangeSelection } from "../../Atoms/DateRange/DateRange";
import { ChipsList } from "../../Molecules/ChipsList/ChipsList";
import "./ChipsSelected.css";

interface ChipsSelectedProps {
  page: number;
  selected: SelectedCoverFilters;
  setSelected: (val: SelectedCoverFilters) => void;
  range: DateRangeSelection;
  filters?: LabelledFilters;
  noEdit?: boolean;
}
export const ChipsSelected = ({
  page,
  selected,
  setSelected,
  range,
  filters,
  noEdit,
}: ChipsSelectedProps) => {
  const handleDelete = (type: CoverEnum, valToDelete: string) => {
    setSelected({
      ...selected,
      [type]: (selected[type] as string[]).filter((i) => i !== valToDelete),
    });
  };

  return (
    <div className={`filter-selected-container ${noEdit && "no-edit"}`}>
      {!noEdit &&
        filters &&
        SHADOW_FILTERS.map((item, idx) => (
          <React.Fragment key={`chip-select-${idx}`}>
            {page > idx && selected[item].length > 0 && (
              <ChipsList
                label={SHADOW_FILTERS_SELECTED[item]}
                data={filters[item]}
                onRemove={(val) => handleDelete(item, val)}
              />
            )}
          </React.Fragment>
        ))}
      {noEdit && filters && (
        <>
          <div className="final-filter">
            <span>{`Cover Number: ${selected[CoverEnum.COVER_NUMBER]}`}</span>
          </div>
          {SHADOW_FILTERS.map((item, idx) => (
            <div key={`filter-final-${idx}`} className="final-filter">
              <span>{`${SHADOW_FILTERS_SELECTED[item]}: ${
                filters[item]
                  ? filters[item].map((i: FilterLabel) => i.label).toString()
                  : ""
              } ${
                (!filters[item] || filters[item].length === 0) && "ALL"
              }`}</span>
            </div>
          ))}
          <div className="final-filter">
            <span>{`Date Range: ${moment(range.startDate).format(
              "MM/DD/YYYY"
            )} to ${moment(range.endDate).format("MM/DD/YYYY")}`}</span>
          </div>
        </>
      )}
    </div>
  );
};
