import moment from "moment";
import React from "react";
import { Button } from "../../../../shared/components/Atoms/Button/Button";
import { Loading } from "../../../../shared/components/Atoms/Loading/Loading";
import { LoadingError } from "../../../../shared/components/Atoms/LoadingError/LoadingError";
import { useApi } from "../../../../shared/hooks/useApi";
import { capitalize } from "../../../../shared/utils/stringUtils";
import MainService from "../../../services/MainService";
import {
  DataReported,
  ShadowReportDetails,
  CoverEnum,
} from "../../../types/ApiTypes";
import { SelectedCoverFilters } from "../../../types/AppTypes";
import { DateRangeSelection } from "../../Atoms/DateRange/DateRange";
import { ReportChart } from "../../Charts/ReportChart/ReportChart";
import "./ChartReport.css";

interface ChartReportProps {
  selected: SelectedCoverFilters;
  range: DateRangeSelection;
  report: DataReported;
  nextPage: () => void;
  goBack: () => void;
}
export const ChartReport = ({
  selected,
  range,
  report,
  nextPage,
  goBack,
}: ChartReportProps) => {
  const [res, error, loading] = useApi<ShadowReportDetails[]>(
    MainService.getShadowReportDetails(
      report,
      selected[CoverEnum.COVER_NUMBER] as string,
      selected[CoverEnum.LOCATIONS] as string[],
      selected[CoverEnum.APPT_TYPE] as string[],
      selected[CoverEnum.PROVIDERS] as string[],
      moment(range.startDate).format("YYYY-MM-DD"),
      moment(range.endDate).format("YYYY-MM-DD")
    ),
    [selected, range, report]
  );

  return (
    <>
      {!error && (
        <div className="filter-table-container result-chart">
          <p className="date-range-select-title">Recall Report Results</p>
          {loading && <Loading full />}
          {res && (
            <>
              <div className="report-chart-wrapper">
                <ReportChart datas={res} />
                <div className="report-chart-filters">
                  <div className="rounded-div" />
                  <div className="report-chart-filters-col">
                    <span className="title">Significant Decline Detected:</span>
                    <div className="report-chart-filters-info">
                      <>
                        {res
                          .filter((item) => item.significantDecline)
                          .sort((a, b) =>
                            moment(a.uploadDate) > moment(b.uploadDate) ? 1 : -1
                          )
                          .map((item, idx) => (
                            <React.Fragment key={`decline-${idx}`}>
                              <span className="subtitle date">
                                {`${capitalize(
                                  item.dayOfWeekName.toLowerCase()
                                )} ${moment(item.uploadDate).format(
                                  "MM/DD/yyyy"
                                )}`}
                              </span>
                              <span className="subtitle percentage">
                                Negative Decline of {item.decrement.toFixed(0)}%
                              </span>
                            </React.Fragment>
                          ))}
                        <span className="subtitle">From 3 month average</span>
                      </>
                    </div>
                  </div>
                </div>
              </div>
              <div className="date-range-container-footer">
                <span className="footer-subtitle">
                  To view report in greated detail, filter results.
                </span>
                <Button label="Back" onClick={() => goBack()} back />
                <Button
                  label="View Results"
                  onClick={nextPage}
                  disabled={false}
                />
              </div>
            </>
          )}
        </div>
      )}
      {error && <LoadingError />}
    </>
  );
};
