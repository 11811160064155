import { Observable } from "rxjs";
import {
  API_ENDPOINT,
  BASE_HEADERS,
} from "../../shared/constants/ApiConstants";
import { ajax, AjaxResponse } from "rxjs/ajax";
import {
  CoverFilters,
  CoverFiltersBody,
  DataReported,
  ShadowReport,
  ShadowReportChartDetails,
  ShadowReportDetails,
} from "../types/ApiTypes";

export const getShadowFilters = (
  body: CoverFiltersBody
): Observable<AjaxResponse<CoverFilters>> => {
  const mappedBody = Object.entries(body).reduce(
    (prev, [key, value]) =>
      (prev = { ...prev, [key]: value.length > 0 ? value : null }),
    {}
  );
  return ajax.post(`${API_ENDPOINT}csshadow/filters`, mappedBody, {
    ...BASE_HEADERS,
  });
};
export const getShadowReport = (
  coverKey: string,
  locations: string[],
  type: string[],
  providers: string[],
  startDate: string,
  endDate: string
): Observable<AjaxResponse<ShadowReport>> => {
  const body = {
    coverKey,
    locations: locations.length > 0 ? locations : ["ALL"],
    apptTypes: type.length > 0 ? type : ["ALL"],
    providers: providers.length > 0 ? providers : ["ALL"],
    startDate,
    endDate,
  };
  return ajax.post(`${API_ENDPOINT}csshadow/upload/report`, body, {
    ...BASE_HEADERS,
  });
};
export const getShadowReportDetails = (
  dataReported: DataReported,
  coverKey: string,
  locations: string[],
  type: string[],
  providers: string[],
  startDate: string,
  endDate: string
): Observable<AjaxResponse<ShadowReportDetails[]>> => {
  const body = {
    dataReported,
    coverKey,
    locations: locations.length > 0 ? locations : ["ALL"],
    apptTypes: type.length > 0 ? type : ["ALL"],
    providers: providers.length > 0 ? providers : ["ALL"],
    startDate,
    endDate,
  };
  return ajax.post(`${API_ENDPOINT}csshadow/upload/report/graph`, body, {
    ...BASE_HEADERS,
  });
};
export const getShadowReportChartDetails = (
  dataReported: DataReported,
  coverKey: string,
  locations: string[],
  type: string[],
  providers: string[],
  startDate: string,
  endDate: string
): Observable<AjaxResponse<ShadowReportChartDetails>> => {
  const body = {
    dataReported,
    coverKey,
    locations: locations.length > 0 ? locations : ["ALL"],
    apptTypes: type.length > 0 ? type : ["ALL"],
    providers: providers.length > 0 ? providers : ["ALL"],
    startDate,
    endDate,
  };
  return ajax.post(`${API_ENDPOINT}csshadow/upload/report/details`, body, {
    ...BASE_HEADERS,
  });
};
