import {
  FilterLabel,
  SelectedCoverFilters,
  LabelledFilters,
} from "../types/AppTypes";

export const getIds = (filters?: FilterLabel[]): string[] | undefined => {
  return filters && filters.map((item) => item.id);
};

export const getSelectedFilters = (
  selected: SelectedCoverFilters,
  all?: LabelledFilters
): LabelledFilters | undefined => {
  return (
    all &&
    Object.entries(all).reduce(
      (prev, [key, value]: [string, FilterLabel[]]) =>
        (prev = {
          ...prev,
          [key]: value.filter((item) => selected[key].includes(item.id)),
        }),
      {} as LabelledFilters
    )
  );
};
