import moment from "moment";
import React from "react";
import { Button } from "../../../../shared/components/Atoms/Button/Button";
import { LoadingError } from "../../../../shared/components/Atoms/LoadingError/LoadingError";
import { useApi } from "../../../../shared/hooks/useApi";
import { formatNumber } from "../../../../shared/utils/formatNumbers";
import { SHADOW_REPORTS_LABELS } from "../../../constants/Dictionary";
import MainService from "../../../services/MainService";
import {
  DataReported,
  ShadowReport,
  CoverEnum,
  ShadowStatus,
} from "../../../types/ApiTypes";
import { SelectedCoverFilters } from "../../../types/AppTypes";
import { DateRangeSelection } from "../../Atoms/DateRange/DateRange";
import { Table } from "../../Molecules/Table/Table";

interface ShadowReportTableProps {
  selected: SelectedCoverFilters;
  range: DateRangeSelection;
  showReport: (val: DataReported) => void;
  goBack: () => void;
}
export const ShadowReportTable = ({
  selected,
  range,
  showReport,
  goBack,
}: ShadowReportTableProps) => {
  const [res, error, loading] = useApi<ShadowReport>(
    MainService.getShadowReport(
      selected[CoverEnum.COVER_NUMBER] as string,
      selected[CoverEnum.LOCATIONS] as string[],
      selected[CoverEnum.APPT_TYPE] as string[],
      selected[CoverEnum.PROVIDERS] as string[],
      moment(range.startDate).format("YYYY-MM-DD"),
      moment(range.endDate).format("YYYY-MM-DD")
    ),
    [selected, range]
  );

  const COLUMNS = [
    "Average Count",
    "Shadow Data Reported",
    "Upload Status",
    "Actions",
  ];

  const DATA =
    res &&
    res
      .sort(
        (a, b) =>
          Object.values(DataReported).indexOf(a.shadowDataReported) -
          Object.values(DataReported).indexOf(b.shadowDataReported)
      )
      .map((item, idx) => [
        formatNumber(item.avgCount, 0),
        SHADOW_REPORTS_LABELS[item.shadowDataReported] ||
          item.shadowDataReported,
        <div
          className={`status-round ${item.uploadStatus || "UNKNOWN"}`}
          key={`round-div-${idx}`}
        >
          <div className="rounded-div" />
          <span>
            {item.uploadStatus === ShadowStatus.RED && "Needs Attention"}
          </span>
        </div>,
        <Button
          key={`view-button-${idx}`}
          label="View"
          onClick={() =>
            item.shadowDataReported && showReport(item.shadowDataReported)
          }
          disabled={
            !item.uploadStatus || item.uploadStatus === ShadowStatus.GREEN
          }
        />,
      ]);

  return (
    <>
      {!error && (
        <div className="filter-table-container">
          <Table
            title="Report Results"
            subTitle="Results based on a 7 - day range"
            colums={COLUMNS}
            datas={DATA || []}
            loading={loading}
            footer={<Button label="Back" onClick={() => goBack()} back />}
          />
        </div>
      )}
      {error && <LoadingError />}
    </>
  );
};
