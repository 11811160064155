import {
  CoverFilters,
  CoverFiltersBody,
  DataReported,
  ShadowReport,
  ShadowReportChartDetails,
  ShadowReportDetails,
} from "./../types/ApiTypes";
import {
  getShadowFilters,
  getShadowReport,
  getShadowReportChartDetails,
  getShadowReportDetails,
} from "./../apis/MainApis";
import { catchError, map, Observable, of } from "rxjs";
import { AjaxError } from "rxjs/ajax";

export class MainService {
  getShadowFilters(
    body: CoverFiltersBody
  ): Observable<CoverFilters | AjaxError> {
    return getShadowFilters(body).pipe(
      map((res) => {
        return { ...res.response };
      }),
      catchError((err) => {
        return of(err as AjaxError);
      })
    );
  }
  getShadowReport(
    coverkey: string,
    locations: string[],
    type: string[],
    providers: string[],
    from: string,
    to: string
  ): Observable<ShadowReport | AjaxError> {
    return getShadowReport(coverkey, locations, type, providers, from, to).pipe(
      map((res) => {
        return [...res.response];
      }),
      catchError((err) => {
        return of(err as AjaxError);
      })
    );
  }
  getShadowReportDetails(
    dataReported: DataReported,
    coverkey: string,
    locations: string[],
    type: string[],
    providers: string[],
    from: string,
    to: string
  ): Observable<ShadowReportDetails[] | AjaxError> {
    return getShadowReportDetails(
      dataReported,
      coverkey,
      locations,
      type,
      providers,
      from,
      to
    ).pipe(
      map((res) => {
        return [...res.response];
      }),
      catchError((err) => {
        return of(err as AjaxError);
      })
    );
  }
  getShadowReportChartDetails(
    dataReported: DataReported,
    coverkey: string,
    locations: string[],
    type: string[],
    providers: string[],
    from: string,
    to: string
  ): Observable<ShadowReportChartDetails | AjaxError> {
    return getShadowReportChartDetails(
      dataReported,
      coverkey,
      locations,
      type,
      providers,
      from,
      to
    ).pipe(
      map((res) => {
        return [...res.response];
      }),
      catchError((err) => {
        return of(err as AjaxError);
      })
    );
  }
}

export default new MainService();
