import { ComputedBarDatum } from "@nivo/bar";
import { ChartData } from "../types/AppTypes";
import { ShadowReportDetails } from "./../types/ApiTypes";

export const getCalculatedBars = (
  bar: ComputedBarDatum<ChartData>,
  datas: ShadowReportDetails[]
) => {
  const barData = datas.find((item) => item.uploadDate === bar.data.data.date);
  const barAlert = barData?.significantDecline;
  const alertOffset = bar.height - (barData ? barData.currentValue : 0);

  const alertPercentage = barData
    ? (barData.currentValue * 100) / barData.avgPastValue
    : 0;

  return {
    barData,
    barAlert,
    alertOffset: Math.abs(
      barAlert && alertPercentage < 5 ? alertOffset - 6 : alertOffset
    ),
  };
};
