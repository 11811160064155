import moment from "moment";
import React, { useState } from "react";
import { LoadingError } from "../../../../shared/components/Atoms/LoadingError/LoadingError";
import { useApi } from "../../../../shared/hooks/useApi";
import { Button } from "../../../../shared/stories/Button.stories";
import MainService from "../../../services/MainService";
import {
  CoverEnum,
  DataReported,
  ShadowReportChartDetails,
} from "../../../types/ApiTypes";
import { SelectedCoverFilters } from "../../../types/AppTypes";
import { Checkbox } from "../../Atoms/Checkbox/Checkbox";
import { DateRangeSelection } from "../../Atoms/DateRange/DateRange";
import { Table } from "../../Molecules/Table/Table";
import "./ChartDetails.css";

interface ChartDetailsProps {
  selected: SelectedCoverFilters;
  range: DateRangeSelection;
  report: DataReported;
  goBack: () => void;
}
export const ChartDetails = ({
  selected,
  range,
  report,
  goBack,
}: ChartDetailsProps) => {
  const [filter, setFilter] = useState<string[]>([]);

  const [res, error, loading] = useApi<ShadowReportChartDetails>(
    MainService.getShadowReportChartDetails(
      report,
      selected[CoverEnum.COVER_NUMBER] as string,
      selected[CoverEnum.LOCATIONS] as string[],
      selected[CoverEnum.APPT_TYPE] as string[],
      selected[CoverEnum.PROVIDERS] as string[],
      moment(range.startDate).format("YYYY-MM-DD"),
      moment(range.endDate).format("YYYY-MM-DD")
    ),
    [selected, range, report]
  );

  const COLUMNS = [
    "Loc Key",
    "Location Name",
    "Provider Name",
    "Upload Date/Time",
    "N. of Uploads",
    <Checkbox
      key="table-header"
      label="Select"
      checked={res && filter.length === res.length}
      setChecked={() =>
        res && setFilter(filter.length !== res.length ? Object.keys(res) : [])
      }
    />,
  ];

  const DATA =
    res &&
    res.map((item, index) => [
      item.pmsLocationID,
      item.locationName,
      item.providerName,
      moment(item.uploadDate).format("MM/DD/YYYY HH:mm:ss"),
      item.nrOfUploads,
      <Checkbox
        key={`filter-row-${index}`}
        checked={filter.includes(`${index}`)}
        setChecked={() =>
          setFilter(
            filter.includes(`${index}`)
              ? filter.filter((i) => i !== `${index}`)
              : [...filter, `${index}`]
          )
        }
      />,
    ]);

  return (
    <>
      <div className="width-chart-details">
        <Table
          title={"Recall Report Results - Sorted"}
          colums={COLUMNS}
          datas={DATA || []}
          loading={loading}
          footer={<Button label="Back" onClick={() => goBack()} back />}
        />
      </div>
      {error && <LoadingError />}
    </>
  );
};
